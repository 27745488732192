import { lazy } from 'react'

const authRoute: Routes = [
    {
        key: 'login',
        path: `/login`,
        component: lazy(() => import('../../Components/LoginPage/LoginPage')),
    },
    
]

export default authRoute
