import { adminSignIn, adminSignUp } from '../../services/AuthService';

import appConfig from '../../configs/app.config.js';
import { REDIRECT_URL_KEY, TOKEN_KEY } from '../../constants/app.constant.js';
import { useNavigate } from 'react-router-dom';
import useQuery from './useQuery';

function useAuth() {
    const navigate = useNavigate();
    const query = useQuery();
    const signIn = async (values) => {
        try {
            const resp = await adminSignIn(values);
            if (resp.data) {
                const token = resp.data.token;
                const tokenWithoutBearer = token.replace('Bearer ', '');
                sessionStorage.setItem(TOKEN_KEY, tokenWithoutBearer);
                const redirectUrl = query.get(REDIRECT_URL_KEY);
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                );
                return {
                    status: 'success',
                    message: '',
                };
            }
        } catch (errors) {
            console.error(errors);
            return {
                status: 'failed',
                message: errors?.response?.data?.description || errors.toString(),
            };
        }
    };
    const signUp = async (values) => {
        try {
            const resp = await adminSignUp(values);
            if (resp.data) {
                navigate('/login');
                return {
                    status: 'success',
                    message: '',
                };
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            };
        }
    };
    const handleSignOut = () => {
        navigate(appConfig.unAuthenticatedEntryPath);
    };

    const signOut = async () => {
        handleSignOut();
    };
    return {
        authenticated: Boolean(sessionStorage.getItem(TOKEN_KEY)),
        signIn,
        signUp,
        signOut,
    };
}
export default useAuth;
