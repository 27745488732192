import appConfig from '../../configs/app.config.js'
import { REDIRECT_URL_KEY } from '../../constants/app.constant.js'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import useAuth from '../../utils/hooks/useAuth'

const { unAuthenticatedEntryPath } = appConfig

const ProtectedRoute = () => {
    const { authenticated } = useAuth();
    const location = useLocation();


    if (!authenticated) {
        const currentPath = location.pathname;
        const redirectUrl = (currentPath && currentPath !== '/') ? `?${REDIRECT_URL_KEY}=${currentPath}` : '';
        const redirectTo = `${unAuthenticatedEntryPath}${redirectUrl}`;

        return <Navigate replace to={redirectTo} />;
    }

    return <Outlet />
}

export default ProtectedRoute
