import axios from 'axios';
import appConfig from '../configs/app.config.js';
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from '../constants/api.constant.js';
import { TOKEN_KEY } from '../constants/app.constant.js';

const unauthorizedCode = [401, 403];

const BaseService = () => {
    const baseURL = appConfig.baseURL || process.env.REACT_APP_API_URL;
    const service = axios.create({
        timeout: 60000,
        baseURL,
    });

    service.interceptors.request.use(
        (config) => {
            const accessToken = sessionStorage.getItem(TOKEN_KEY);
            if (accessToken) {
                config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    service.interceptors.response.use(
        (response) => response,
        (error) => {
            const { response } = error;
            if (response && unauthorizedCode.includes(response.status)) {
                sessionStorage.removeItem(TOKEN_KEY);
                window.location.href = appConfig.unAuthenticatedEntryPath;
            }

            return Promise.reject(error);
        }
    );

    return service;
};

export default BaseService;
