import { Navigate, Outlet } from 'react-router-dom'
import appConfig from '../../configs/app.config.js'
import useAuth from '../../utils/hooks/useAuth'

const { authenticatedEntryPath } = appConfig

const PublicRoute = () => {
    const { authenticated } = useAuth();
    return authenticated ? <Navigate to={authenticatedEntryPath} /> : <Outlet />
}

export default PublicRoute
