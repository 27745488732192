import ApiService from './ApiService'


export async function adminSignUp(data) {
    const json = JSON.stringify({
        username: data.username,
        password: data.password,
        restaurantBranchName: data.restaurantBranchName
    });
    return ApiService.fetchData({
        url: 'auth/signup',
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        data: json,
    })
}
export async function adminSignIn(data) {
    const json = JSON.stringify({
        username: data.username,
        password: data.password,
        restaurantBranchName: data.restaurantBranchName
    });
    return ApiService.fetchData({
        url: '/auth/login', // Ensure the URL starts with a slash to append to the base URL
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        data: json,
    });
}



export async function adminSignOut() {
    return ApiService.fetchData({
        url: 'auth/logout',
        method: 'post',
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}

export async function fetchSuppliers(page, size) {
    return ApiService.fetchData({
        url: `/suppliers?page=${page}&size=${size}`,
        method: 'get',
    });
}

export async function fetchSupplierItems(supplierId, page, size) {
    return ApiService.fetchData({
        url: `/suppliers/getSupplierItems?supplierId=${supplierId}&page=${page}&size=${size}`,
        method: 'get',
    });
}

export async function fetchRecipes() {
    return ApiService.fetchData({
        url: `/recipes/possibleRecipes`,
        method: 'get',
    });
}

export async function fetchDrinks() {
    return ApiService.fetchData({
        url: `/recipes/possibleDrinks`,
        method: 'get',
    });
}

export async function fetchInventory() {
    return ApiService.fetchData({
        url: `/inventory/getInventoryItemsByCategory`,
        method: 'get',
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export async function fetchItems() {
    return ApiService.fetchData({
        url: `/inventory/getInventoryItems`,
        method: 'get'
    });
}

export async function fetchExpiringItems(page, size) {
    return ApiService.fetchData({
        url: `/inventory/expiringItems?page=${page}&size=${size}`,
        method: 'get',
    });
}

export async function fetchLowStockItems(page, size) {
    return ApiService.fetchData({
        url: `inventory/lowStockItems?page=${page}&size=${size}`,
        method: 'get',
    });
}

export async function addRecipe(data) {
    return ApiService.fetchData({
        url: '/recipes/addRecipe',
        method: 'post',
        data: data,
    });
}

export async function addDrink(data) {
    return ApiService.fetchData({
        url: '/recipes/addDrink',
        method: 'post',
        data: data,
    });
}

export async function editRecipeOrDrink(recipeId, data) {
    console.log("reciped", recipeId);
    return ApiService.fetchData({
        url: `/recipes/updateRecipeOrDrink/${recipeId}`,
        method: 'put',
        data: data,
    });
}
export async function deleteRecipeOrDrink(recipeId) {
    return ApiService.fetchData({
        url: `/recipes/deleteRecipeOrDrink/${recipeId}`,
        method: 'DELETE',
    });
}

export async function addItem(data) {
    return ApiService.fetchData({
        url: '/inventory/addInventoryItem',
        method: 'post',
        data: data,
    });
}

export async function editItem(itemId, data) {
    return ApiService.fetchData({
        url: `/inventory/updateInventoryItem/${itemId}`,
        method: 'put',
        data: data,
    });
}

export async function deleteItem(itemId) {
    return ApiService.fetchData({
        url: `/inventory/deleteInventoryItem/${itemId}`,
        method: 'delete',
    });
}

export async function addSuplier(data) {
    return ApiService.fetchData({
        url: '/suppliers/addSupplier',
        method: 'post',
        data: data,
    });
}

export async function editSuplierData(id, data) {
    return ApiService.fetchData({
        url: `/suppliers/updateSupplier/${id}`,
        method: 'put',
        data: data,
    });
}

export async function deleteSuplierData(id) {
    return ApiService.fetchData({
        url: `/suppliers/deleteSupplier/${id}`,
        method: 'delete'
    });
}

export async function addShiftSlots(data) {
    return ApiService.fetchData({
        url: '/shiftSlots/updateAll',
        method: 'put',
        data: data,
    });
}

export async function getShiftSlots() {
    return ApiService.fetchData({
        url: `/shiftSlots`,
        method: 'get'
    });
}

export async function getShiftSlotTimings() {
    return ApiService.fetchData({
        url: `/shiftSlots/shiftTimings`,
        method: 'get'
    });
}

export async function addShiftTimings(data) {
    return ApiService.fetchData({
        url: '/shiftSlots/addShiftTiming',
        method: 'post',
        data: data,
    });
}

export async function updateShiftTimings(id, data) {
    return ApiService.fetchData({
        url: `/shiftSlots/updateShiftTiming/${id}`,
        method: 'put',
        data: data,
    });
}

export async function addEmployee(data) {
    return ApiService.fetchData({
        url: '/employees/add',
        method: 'post',
        data: data,
    });
}

export async function getEmployees() {
    return ApiService.fetchData({
        url: `/employees`,
        method: 'get'
    });
}

export async function assignShiftSlots(startDay, endDay) {
    return ApiService.fetchData({
        url: `/employees/assign?startDay=${startDay}&endDay=${endDay}`,
        method: 'get'
    });
}

export async function getAvailableShiftSlots() {
    return ApiService.fetchData({
        url: `/employees/getEmployeeShifts`,
        method: 'get'
    });
}

export async function getEmployeesByPreference(dayOfWeek, shiftTimingId) {
    return ApiService.fetchData({
        url: `/employees/getEmployeesByPreference?dayOfWeek=${dayOfWeek}&shiftTimingId=${shiftTimingId}`,
        method: 'get'
    });
}

export async function updateEmployeeShift(data) {
    return ApiService.fetchData({
        url: '/employees/updateShift',
        method: 'post',
        data: data,
    });
}

export async function deleteEmployee(id) {
    return ApiService.fetchData({
        url: `/employees/delete/${id}`,
        method: 'delete',
    });
}

export async function updateEmployee(id, data) {
    return ApiService.fetchData({
        url: `/employees/update/${id}`,
        method: 'put',
        data: data,
    });
}

export async function addRecipeToMenu(data) {
    return ApiService.fetchData({
        url: '/menu/addManualMenu',
        method: 'post',
        data: data,
    });
}

export async function getAutomaticMenu() {
    return ApiService.fetchData({
        url: '/menu/getAutomaticMenu',
        method: 'get',
    });
}

export async function removeRecipeFromInventory(recipeId, recipeQuantity) {
    return ApiService.fetchData({
        url: `/inventory/removeRecipeFromInventory?recipeId=${recipeId}&recipeQuantity=${recipeQuantity}`,
        method: 'post',
    });
}

export async function removeItemFromInventory(serialNumber, itemQuantity) {
    return ApiService.fetchData({
        url: `/inventory/removeItemFromInventory?serialNumber=${serialNumber}&itemQuantity=${itemQuantity}`,
        method: 'post',
    });
}
export async function getManualMenu() {
    return ApiService.fetchData({
        url: '/menu/getManualMenu',
        method: 'get',
    });
}

export async function getCalculatedMenu() {
    return ApiService.fetchData({
        url: '/menu/getCalculatedMenu',
        method: 'get',
    });
}

export async function addCalculatedMenu(data) {
    return ApiService.fetchData({
        url: '/menu/addCalculatedMenu',
        method: 'post',
        data: data,
    });
}

export async function getStockValues() {
    return ApiService.fetchData({
        url: '/inventory/getStockInfo',
        method: 'get',
    });
}

export async function getBranchDetails() {
    return ApiService.fetchData({
        url: '/restaurantBranch',
        method: 'get',
    });
}

export async function addInventoryItem(data) {
    return ApiService.fetchData({
        url: '/inventory/addInventoryItemQuantity',
        method: 'post',
        data: data,
    });
}

export async function getItems() {
    return ApiService.fetchData({
        url: '/items',
        method: 'get',
    });
}

export async function getPrecookedRecipes() {
    return ApiService.fetchData({
        url: '/precookedRecipes',
        method: 'get',
    });
}

export async function addPrecookedRecipes(data) {
    return ApiService.fetchData({
        url: '/precookedRecipes/addRecipe',
        method: 'post',
        data: data,
    });
}

export async function deletePrecookedRecipe(recipeId) {
    return ApiService.fetchData({
        url: `/precookedRecipes/deletePrecookedRecipe/${recipeId}`,
        method: 'delete',
    });
}

export async function updatePrecookedRecipe(recipeId, data) {
    return ApiService.fetchData({
        url: `/precookedRecipes/updatePrecookedRecipe/${recipeId}`,
        method: 'put',
        data: data,
    });
}

export async function updateLocation(data) {
    return ApiService.fetchData({
        url: `/restaurantBranch/update`,
        method: 'put',
        data: data,
    });
}

export async function updateProfile(data) {
    return ApiService.fetchData({
        url: `/users/update`,
        method: 'put',
        data: data,
    });
}

export async function getProfileData() {
    return ApiService.fetchData({
        url: `/users/me`,
        method: 'get',
    });
}
export async function changeNutritionValue(id, nutrition) {
    return ApiService.fetchData({
        url: `/recipes/updateNutrition/${id}?nutrition=${nutrition}`,
        method: 'post',
    });
}

export async function deleteManualMenuRecipe(menuId, recipeId) {
    return ApiService.fetchData({
        url: `/menu/removeRecipeFromManualMenu?menuId=${menuId}&menuRecipeId=${recipeId}`,
        method: 'delete',
    });
}

export async function getSearchData(searchValue) {
    return ApiService.fetchData({
        url: `/search?keyword=${searchValue}`,
        method: 'get',
    });
}

export async function getNotifications() {
    return ApiService.fetchData({
        url: `/notifications`,
        method: 'get',
    });
}

export async function getCartData() {
    return ApiService.fetchData({
        url: `/cart/getCart`,
        method: 'get',
    });
}
export async function addToCart(data) {
    return ApiService.fetchData({
        url: `/cart/addToCart`,
        method: 'post',
        data: data,
    });
}
export async function updateCartItem(data) {
    return ApiService.fetchData({
        url: `/cart/updateCartItem`,
        method: 'put',
        data: data,
    });
}
export async function deleteCartItem(itemId) {
    return ApiService.fetchData({
        url: `/cart/removeCartItem/${itemId}`,
        method: 'delete',
    });
}
export async function setPricesAllRecipes(data) {
    return ApiService.fetchData({
        url: `/price-attributes/set-common`,
        method: 'post',
        data: data,
    });
}

export async function deductRecipes(recipeId, quantity) {
    return ApiService.fetchData({
        url: `/inventory/removeRecipeFromInventory?recipeId=${recipeId}&recipeQuantity=${quantity}`,
        method: 'post',
    });
}

export async function getAllRecipes() {
    return ApiService.fetchData({
        url: `/recipes/allRecipes?page=0&size=30`,
        method: 'get',
    });
}

export async function getAllDrinks() {
    return ApiService.fetchData({
        url: `/recipes/allDrinks?page=0&size=30`,
        method: 'get',
    });
}