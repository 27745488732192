import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import PublicRoute from './Components/route/PublicRoute';
import ProtectedRoute from './Components/route/ProtectedRoute'
import appConfig from './configs/app.config.js'


import {publicRoutes, protectedRoutes} from './configs/routes.config/routes.config.js'

function App() {
  const { authenticatedEntryPath } = appConfig

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route
              path="/"
              element={<Navigate replace to={authenticatedEntryPath} />}
          />
          {protectedRoutes.map((route, index) => (
              <Route
                  key={route.key + index}
                  path={route.path}
                  element={
                      <route.component/>
                  }
              />
          ))}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Route>
        <Route path="/" element={<PublicRoute />}>
            {publicRoutes.map((route) => (
                <Route
                    key={route.path}
                    path={route.path}
                    element={
                        <route.component/>
                    }
                />
            ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
