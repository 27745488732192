import { lazy } from 'react'
import authRoute from './authRoute'

export const publicRoutes = [...authRoute]
export const protectedRoutes = [
    {
        key: 'dashboard',
        path: '/dashboard/*',
        component: lazy(() => import('../../Components/Dashboard/Dashboard')),
        
    }
]