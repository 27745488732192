import Enviroment from "../config.json";
const BASE_URL = Enviroment.Enviroment.dev.BASE_API_URL ?? "";

const appConfig = {
    baseURL: BASE_URL,
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: '/login',
}

export default appConfig
