import BaseService from './BaseService';

const ApiService = {
    fetchData: async (options) => {
        try {
            const service = BaseService();
            const response = await service({
                ...options
            });
            return response;
        } catch (error) {
            throw error;
        }
    },
}
export default ApiService;

